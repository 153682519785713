import { Center, Heading, Box, PinInput, PinInputField, HStack } from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useState, useRef} from "react";
import "../styles/app.css";
import "../styles/confirmEmail.css";

export default function ConfirmEmail() {
    const navigate = useNavigate();
    const [validationCode, setValidationCode] = useState();
    const pin1 = useRef();
    const pin2 = useRef();
    const pin3 = useRef();
    const pin4 = useRef();
    const pin5 = useRef();
    const pin6 = useRef();
    const errorRef = useRef();

    const refs = [pin1, pin2, pin3, pin4, pin5, pin6];

    const submitCode = (code) => {
        if (code.length !== 6) {
            return;
        }

        for (const ref of refs) {
            ref.current.disabled = true;
        }

        axios.get("https://noz-games-api.krbk.dev/confirmEmail/"+localStorage.getItem("username")+"/"+code).then((res) => {
            if (res.status === 200) {
                navigate("/login");
            } else {
                for (const ref of refs) {
                    ref.current.disabled = false;
                    ref.current.value = "";
                }
                refs[0].current.focus();
                errorRef.current.innerHTML = "Le code de validation est incorrect";
            }
        }).catch((err) => {
            console.log(err);
            for (const ref of refs) {
                ref.current.disabled = false;
                ref.current.value = "";
            }
            refs[0].current.focus();
            errorRef.current.innerHTML = "Une erreur est survenue";
        });

    }

    return (
        <div className={"app"}>
            <Center h="100vh">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Heading as="h1" size="2xl" className={"text head"}>
                        Confirme ton adresse mail
                    </Heading>
                    <Heading as="h3" size="md" className={"text head text-center"}>
                        On t'a envoyé un code de validation par mail. Entre-le ici pour confirmer ton compte.
                    </Heading>

                    <HStack>
                        <PinInput onComplete={(c) => {
                            submitCode(c);
                        }} size="lg">
                            <PinInputField ref={pin1} />
                            <PinInputField ref={pin2} />
                            <PinInputField ref={pin3} />
                            <PinInputField ref={pin4} />
                            <PinInputField ref={pin5} />
                            <PinInputField ref={pin6} />
                        </PinInput>
                    </HStack>

                    <p ref={errorRef} className={"error"}></p>
                </Box>
            </Center>
        </div>
    );
}
