import { Center, Heading, Box } from "@chakra-ui/react";
import "../styles/app.css";
import {useNavigate, Link} from "react-router-dom";

export default function App() {
    const navigate = useNavigate();

    return (
        <div className={"app"}>
            <Center h="100vh">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Heading as="h1" size="2xl" className={"text"}>
                        Cette fonctionnalité n'est pas encore terminée.<br/>
                        Tu peux <Link to={"/home"} className={"text link"}>retourner à l'accueil</Link>.
                    </Heading>
                </Box>
            </Center>
        </div>
    );
}
