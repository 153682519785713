import { Center, Heading, Box } from "@chakra-ui/react";
import "../styles/app.css";
import {useNavigate} from "react-router-dom";
import {useState, useEffect} from "react";
import axios from "axios";
import statusCode from "../utils/statusCode";

export default function WelcomeToConvention() {
    const navigate = useNavigate();

    useEffect(() => {

        const url = new URL(window.location.href);
        const cid = url.searchParams.get("convention");

        axios.post("https://noz-games-api.krbk.dev/convention/"+cid+"/hey", {

        }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            setUserData({username: res.data.username});
            setConvention({name: res.data.conventionName});
            setPresenceAcquired(true);
            setMessage(`Bienvenue à la convention ${convention.name}${userData.username ? `, ${userData.username}.` : "."}`)
        }).catch((err) => {
            if (err.response.status === 401) {
                navigate("/login");
            }
            if (err.response.data.sc) {
                const code = err.response.data.sc;
                setMessage(<span>
                    Une erreur est survenue: <br/>{
                    statusCode[code.toString()]
                }
                </span>);
            }
        });
    }, [])

    const [userData, setUserData] = useState({});
    const [convention, setConvention] = useState({});
    const [presenceAcquired, setPresenceAcquired] = useState(false);
    const [message, setMessage] = useState("");

    return (
        <div className={"app"}>
            {
                userData.username ? (
                    <Center h="100vh">
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Heading as="h1" size="2xl" className={"text"}>
                                {message}
                            </Heading>
                        </Box>
                    </Center>
                ) : (
                    <Center h="100vh">
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Heading as="h1" size="2xl" className={"text m-10"}>
                                {
                                    (message === "") ? (
                                        "Chargement..."
                                    ) : (
                                        <div>
                                            {message}
                                        </div>
                                    )
                                }
                            </Heading>
                        </Box>
                    </Center>
                )
            }
        </div>
    );
}
