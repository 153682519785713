import { Box, Center, Heading, Text, Code } from "@chakra-ui/react";
import "../styles/app.css";
import axios from "axios";

export default function GameRequest({ gameRequest }) {
    return (
        <Box
            w="100%"
            bg="#333333"
            borderRadius="10px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{padding: "15px"}}
        >
            <Heading as="h1" size="md" className={"text"}>
                {gameRequest.gameName}
            </Heading>
            <Text className={"text"} style={{textAlign: "center"}}>
                <span style={{fontSize: "0.8em"}}>Lorsque l'animateur vous demande un code, donnez lui le suivant : <Code>{gameRequest.confirmCode}</Code> </span>
            </Text>
            <button className={"button-glow"} style={{marginTop: "10px"}} onClick={() => {
                axios.post("https://noz-games-api.krbk.dev/user/@me/cancelPlayRequest", {

                }, {
                    headers: {
                        token: localStorage.getItem("token")
                    }
                }).then(() => {
                    window.location.reload();
                });
            }}>Annuler la requête</button>
        </Box>
    );
}
