import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {ChakraProvider} from "@chakra-ui/react";
import "./style.css";

import App from "./pages/App";
import Register from "./pages/Register";
import ConfirmEmail from "./pages/confirmEmail";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import GameRequests from "./pages/GameRequests";
import WelcomeToConvention from "./pages/WelcomeToConvention";
import PlayGame from "./pages/PlayGame";
import AdminPanel from "./pages/Admin";
import AdminConvention from "./pages/AdminConvention";
import AdminGame from "./pages/AdminGame";
import Page404 from "./pages/404";

const root = ReactDOM.createRoot(
    document.getElementById('root')
);

root.render(
    <ChakraProvider>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/register" element={<Register />} />
                <Route path="/confirmEmail" element={<ConfirmEmail />} />
                <Route path="/login" element={<Login />} />
                <Route path="/home" element={<Home />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/game" element={<GameRequests />} />
                <Route path="/convention" element={<WelcomeToConvention />} />
                <Route path="/play" element={<PlayGame />} />
                <Route path="/admin" element={<AdminPanel />} />
                <Route path="/admin/convention" element={<AdminConvention />} />
                <Route path="/admin/game" element={<AdminGame />} />

                <Route path="*" element={<Page404 />} />
            </Routes>
        </BrowserRouter>
    </ChakraProvider>
)
