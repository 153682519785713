import { Center, Heading, Box, Select } from "@chakra-ui/react";
import "../styles/app.css";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useState, useEffect} from "react";

export default function AdminPanel() {
    const navigate = useNavigate();

    const [conventions, setConventions] = useState([]);

    useEffect(() => {
        axios.get("https://noz-games-api.krbk.dev/conventionList", {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            setConventions(res.data);
        })
    }, [])

    return (
        <div className={"app"}>
            <Center h="100vh">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Heading as="h1" size="2xl" className={"text mb-15"}>
                        Salut l'admin 👋
                    </Heading>
                    <Select placeholder="Sélectionne une convention" onInput={(e) => {
                        navigate("/admin/convention?id=" + e.target.value);
                    }}>
                        {
                            conventions.map((convention) => {
                                return (
                                    <option value={convention.cid}>{convention.name}</option>
                                )
                            })
                        }
                    </Select>
                </Box>
            </Center>
        </div>
    );
}
