/*
- 1007: Missing one or more required fields
- 1003: Invalid email
- 1006: Username already exists
- 1005: Email already exists
- 1004: Invalid gender
- 1000: Invalid birthdate (invalid date)
- 1001: Invalid birthdate (date in the future)
- 1002: Invalid birthdate (too young)
- 1009: User not found
- 1008: Invalid password
- 1010: Missing token
- 1011: Invalid token
- 1012: Invalid username
- 1013: Invalid password (does not meet requirements)
- 1014: No body provided
- 1015: A password reset email has already been sent in the last 10 minutes
- 1016: Invalid password reset token
- 1017: Invalid password reset token (expired)
- 1018: Missing permission
- 1019: Game not found
 */

export default {
    "1007": "Un ou plusieurs champs requis sont manquants",
    "1003": "L'adresse email n'est pas valide",
    "1006": "Le nom d'utilisateur est déjà utilisé",
    "1005": "L'adresse email est déjà utilisée",
    "1004": "Le genre n'est pas valide",
    "1000": "La date de naissance n'est pas valide",
    "1001": "La date de naissance est dans le futur",
    "1002": "Vous êtes trop jeune pour vous inscrire",
    "1009": "L'utilisateur n'existe pas",
    "1008": "Le mot de passe est incorrect",
    "1010": "Le token est manquant",
    "1011": "Le token est invalide",
    "1012": "Le nom d'utilisateur est invalide",
    "1013": "Le mot de passe ne respecte pas les critères",
    "1014": "Aucun corps n'a été fourni",
    "1015": "Un email de réinitialisation de mot de passe a déjà été envoyé dans les 10 dernières minutes",
    "1016": "Le token de réinitialisation de mot de passe est invalide",
    "1017": "Le token de réinitialisation de mot de passe est invalide (expiré)",
    "1018": "Vous n'avez pas la permission d'accéder à cette ressource",
    "1019": "Le jeu n'existe pas",
    "1022": "Vous participez déjà à cette convention",
    "1024": "Vous avez déjà demandé à jouer à un jeu."
}
