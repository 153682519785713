import { Center, Heading, Box, Select } from "@chakra-ui/react";
import "../styles/app.css";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useState, useEffect, useRef} from "react";

export default function AdminPanel() {
    const navigate = useNavigate();

    const [games, setGames] = useState([]);
    const timerRef = useRef();

    const url = new URL(window.location.href);
    const cid = url.searchParams.get("convention");
    const gid = url.searchParams.get("id");

    const [conventionId, setConventionId] = useState(cid);
    const [gameName, setGameName] = useState("");
    const [currentPlayingUser, setCurrentPlayingUser] = useState("");
    const [intervalId, setIntervalId] = useState(null);

    useEffect(() => {
        axios.get("https://noz-games-api.krbk.dev/game/"+gid, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            setGameName(res.data.name);
        });

        const checkForRequests = () => {
            axios.get("https://noz-games-api.krbk.dev/convention/"+conventionId+"/game/"+gid+"/getPlayRequests", {
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then((res) => {
                setGames(res.data);
            });
        }

        window.checkForRequests = checkForRequests;

        checkForRequests();

        setIntervalId(setInterval(() => {
            checkForRequests();
        }, 2000))
    }, []);

    return (
        <div className={"app"}>
            <Center h="100vh">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Heading as="h1" size="2xl" className={"text mb-15"}>
                        {gameName}
                    </Heading>
                    {
                        games.length > 0 ? (
                            <div>
                                {
                                    !currentPlayingUser ? (
                                        <div>
                                            <Heading as="h2" size="xl" className={"text mb-15"}>
                                                Un joueur est en attente !
                                            </Heading>
                                            <Center>
                                                <button className={"button-glow"} onClick={() => {
                                                    const code = prompt("Entrez le code fourni par le joueur");
                                                    axios.post("https://noz-games-api.krbk.dev/convention/"+conventionId+"/game/"+gid+"/setIsPlaying", {
                                                        confirmCode: code,
                                                        prid: games[0]
                                                    }, {
                                                        headers: {
                                                            token: localStorage.getItem("token")
                                                        }
                                                    }).then((res) => {
                                                        if (res.data.success) {
                                                            console.log("success", res.data);
                                                            setCurrentPlayingUser(games[0]);
                                                            clearInterval(intervalId);
                                                            const startTime = Date.now();
                                                            setIntervalId(setInterval(() => {
                                                                timerRef.current.innerText = /* this is a 5 minutes timer, starting from 00:5:00 */ (4 - Math.floor((Date.now() - startTime) / 1000 / 60)) + ":" + (60 - Math.floor((Date.now() - startTime) / 1000 % 60));
                                                                if (startTime + 1000 * 60 * 5 < Date.now()) {
                                                                    clearInterval(intervalId);
                                                                    timerRef.current.innerText = "00:00";
                                                                    axios.post("https://noz-games-api.krbk.dev/convention/"+conventionId+"/game/"+gid+"/makeThemStop", {
                                                                        prid: games[0]
                                                                    }, {
                                                                        headers: {
                                                                            token: localStorage.getItem("token")
                                                                        }
                                                                    }).then(() => {
                                                                        window.location.reload();
                                                                    })
                                                                    setIntervalId(setInterval(() => {
                                                                        window.checkForRequests();
                                                                    }, 2000));
                                                                    setCurrentPlayingUser("");
                                                                }

                                                            }))
                                                        } else {
                                                            alert("Le code est incorrect");
                                                        }
                                                    }).catch((err) => {
                                                        console.error(err);
                                                        alert("Une erreur est survenue");
                                                    });
                                                }}>
                                                    Accepter
                                                </button>
                                            </Center>
                                        </div>
                                    ) : (
                                        <div>
                                            <Heading as="h2" size="xl" className={"text mb-15"}>
                                                Temps restant: <span ref={timerRef}></span>
                                            </Heading>
                                            <Center>
                                                <button className={"button-glow"} onClick={() => {
                                                    axios.post("https://noz-games-api.krbk.dev/convention/" + conventionId + "/game/" + gid + "/makeThemStop", {
                                                        prid: games[0]
                                                    }, {
                                                        headers: {
                                                            token: localStorage.getItem("token")
                                                        }
                                                    }).then(() => {
                                                        window.location.reload();
                                                    });
                                                }}>
                                                    Arrêtez le joueur
                                                </button>
                                            </Center>
                                        </div>
                                    )
                                }
                            </div>
                        ) : (
                            <Heading as="h1" size="2xl" className={"text mb-15"}>
                                Aucune demande de jeu pour le moment
                            </Heading>
                        )
                    }
                </Box>
            </Center>
        </div>
    );
}
