import { Center, Heading, Box } from "@chakra-ui/react";
import "../styles/app.css";
import {Link, useNavigate} from "react-router-dom";
import GameRequest from "../components/GameRequest";
import {useState, useEffect} from "react";
import axios from "axios";

export default function App() {
    const navigate = useNavigate();

    const [gameRequests, setGameRequests] = useState([]);

    useEffect(() => {
        axios.get("https://noz-games-api.krbk.dev/user/@me/playRequests", {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            setGameRequests(res.data);
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    return (
        <div className={"app"}>
            <Center h="100vh">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Heading as="h1" size="2xl" className={"text mb-15"}>
                        Requête de jeu
                    </Heading>
                    {
                        gameRequests.length === 0 ? (
                            <Heading as="h3" size="md" className={"text"} style={{textAlign: "center"}}>
                                Aucune requête de jeu pour le moment. Tu peux <Link to={"/home"} className={"link"}>retourner à l'accueil</Link> ou <button onClick={() => {
                                    window.location.reload();
                            }} className={"link"} style={{fontWeight: "bold"}}>actualiser</button>.
                            </Heading>
                        ) : (
                            <div style={{marginLeft: "25px", marginRight: "25px"}}>
                                {
                                    gameRequests.map((gameRequest) => {
                                        return <GameRequest gameRequest={gameRequest} />
                                    })
                                }
                            </div>
                        )
                    }
                </Box>
            </Center>
        </div>
    );
}
