import { Center, Heading, Box } from "@chakra-ui/react";
import "../styles/app.css";
import {useNavigate} from "react-router-dom";

export default function App() {
    const navigate = useNavigate();

    return (
        <div className={"app"}>
            <Center h="100vh">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Heading as="h1" size="2xl" className={"text"}>
                        404.
                    </Heading>
                    <Heading as="h1" size="lg" className={"text"}>
                        Je ne trouve pas cette page.
                    </Heading>
                </Box>
            </Center>
        </div>
    );
}
