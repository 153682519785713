import { Center, Heading, Box, Input, FormControl, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import "../styles/app.css";
import "../styles/register.css";
import axios from "axios";
import {useNavigate, Link} from "react-router-dom";
import {useRef, useState} from "react";
import errorCodes from "../utils/statusCode";

export default function Login() {
    const navigate = useNavigate();

    const [usernameOrEmail, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const errorRef = useRef();
    const passwordRef = useRef();
    const usernameRef = useRef();

    const allRefs = [usernameRef, passwordRef];

    const submitLogin = () => {
        for (let ref of allRefs) {
            if (ref.current.value === "") {
                errorRef.current.innerHTML = "Veuillez remplir tous les champs";
                return;
            }
        }

        for (const ref of allRefs) {
            ref.current.disabled = true;
        }

        axios.post("https://noz-games-api.krbk.dev/user/login", {
            username: usernameOrEmail,
            password: password
        }).then((res) => {
            if (res.data.token) {
                localStorage.setItem("token", res.data.token);
                navigate("/home");
            } else {
                errorRef.current.innerHTML = res.data.message;
                for (const ref of allRefs) {
                    ref.current.disabled = false;
                }
            }
        }).catch((err) => {
            console.log(err);
        const errorCode = err.response?.data?.sc;
        errorRef.current.innerHTML = errorCodes[errorCode] || "Une erreur est survenue";
        console.log(errorCode, errorCodes[errorCode.toString()]);
        for (const ref of allRefs) {
            ref.current.disabled = false;
        }
        });
    }

    return (
        <div className={"app"}>
            <Center h="100vh">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Heading as="h1" size="2xl" className={"text mb-15"}>
                        Connexion
                    </Heading>
                    <Box display="flex" flexDirection="column" alignItems="center" w="50vw">
                        <FormControl>
                            <Input placeholder="Nom d'utilisateur" className={"input"} ref={usernameRef} onInput={(e) => {
                                setUsername(e.target.value);
                            }} />
                            <Input placeholder="Mot de passe" className={"input"} type="password" ref={passwordRef} onInput={(e) => {
                                setPassword(e.target.value);
                            }}/>

                            <Center>
                                <Box flex={1} textAlign="center" flexDirection={"column"}>
                                    <button className={"button-glow m-10"} onClick={submitLogin}>
                                        Se connecter
                                    </button>
                                    <p className={"error"} ref={errorRef}></p>
                                </Box>
                            </Center>
                        </FormControl>
                    </Box>
                    <p className={"text accountAlready"}>Pas de compte ? Tu peux t'en <Link to={"/register"} className={"link"}>créer un</Link> !</p>
                </Box>
            </Center>
        </div>
    );
}
