import { Center, Heading, Box, Select } from "@chakra-ui/react";
import "../styles/app.css";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useState, useEffect} from "react";

export default function AdminPanel() {
    const navigate = useNavigate();

    const [games, setGames] = useState([]);

    const url = new URL(window.location.href);
    const cid = url.searchParams.get("id");

    const [conventionId, setConventionId] = useState(cid);

    useEffect(() => {
        axios.get("https://noz-games-api.krbk.dev/convention/"+conventionId+"/games", {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            setGames(res.data.games);
        });
    }, []);

    return (
        <div className={"app"}>
            <Center h="100vh">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Heading as="h1" size="2xl" className={"text mb-15"}>
                        Préparons les jeux !
                    </Heading>
                    <Select placeholder="Sélectionne un jeu" onInput={(e) => {
                        window.location = "/admin/game?id=" + e.target.value+"&convention="+conventionId;
                    }}>
                        {
                            games.map((game) => {
                                return (
                                    <option value={game.gid}>{game.name}</option>
                                )
                            })
                        }
                    </Select>
                </Box>
            </Center>
        </div>
    );
}
