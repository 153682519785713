import { Center, Heading, Box, Input, FormControl, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import "../styles/app.css";
import "../styles/register.css";
import axios from "axios";
import {useNavigate, Link} from "react-router-dom";
import {useRef, useState} from "react";
import departements from "../validation/departements-region.json";

export default function Register() {
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [prenom, setPrenom] = useState("");
    const [genre, setGenre] = useState("M");
    const [dateNaissance, setDateNaissance] = useState("");
    const [departement, setDepartement] = useState("");

    // refs
    const usernameRef = useRef();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const emailRef = useRef();
    const nameRef = useRef();
    const prenomRef = useRef();
    const genreRef = useRef();
    const dateNaissanceRef = useRef();
    const errorRef = useRef();
    const departementRef = useRef();

    const departements_parsed = departements.map((dep) => {
        return {
            label: dep.dep_name+" - "+dep.num_dep+" ("+dep.region_name+")",
            value: dep.num_dep
        }
    });

    const allRefs = [usernameRef, passwordRef, confirmPasswordRef, emailRef, nameRef, prenomRef, genreRef, dateNaissanceRef];

    const submitRegister = () => {
        // do all the checks
        if (password !== confirmPassword) {
            errorRef.current.innerHTML = "Les mots de passe ne correspondent pas";
            return;
        }

        if (!email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
            errorRef.current.innerHTML = "L'adresse email n'est pas valide";
            return;
        }

        if (!dateNaissance.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
            errorRef.current.innerHTML = "La date de naissance n'est pas valide";
            return;
        }

        if (!genre.match(/^M|F|O$/)) {
            errorRef.current.innerHTML = "Le genre n'est pas valide";
            return;
        }

        if (username.length < 3) {
            errorRef.current.innerHTML = "Le nom d'utilisateur est trop court";
            return;
        }

        if (password.length < 6) {
            errorRef.current.innerHTML = "Le mot de passe est trop court";
            return;
        }

        if (name == "" || prenom == "" || dateNaissance == "" || genre == "" || email == "" || username == "" || password == "") {
            errorRef.current.innerHTML = "Veuillez remplir tous les champs";
            return;
        }

        for (const ref of allRefs) {
            ref.current.disabled = true;
        }
        // send the request
        axios.post("https://noz-games-api.krbk.dev/user/register", {
            username: username,
            password: password,
            email: email,
            gender: genre,
            firstName: prenom,
            lastName: name,
            birthDate: dateNaissance
        }).then((response) => {
            if (response.data.error) {
                errorRef.current.innerHTML = response.data.error;
                for (const ref of allRefs) {
                    ref.current.disabled = false;
                }
            } else {
                localStorage.setItem("username", username);
                navigate("/confirmEmail");
            }
        }).catch((error) => {
            errorRef.current.innerHTML = "Une erreur s'est produite";
            for (const ref of allRefs) {
                ref.current.disabled = false;
            }
        });
    }

    return (
        <div className={"app"}>
            <Center h="100vh">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Heading as="h1" size="2xl" className={"text mb-15"}>
                        Inscription
                    </Heading>
                    <Box display="flex" flexDirection="column" alignItems="center" w="50vw">
                        <FormControl>
                            <Input placeholder="Nom d'utilisateur" className={"input"} ref={usernameRef} onInput={(e) => {
                                setUsername(e.target.value);
                            }} />
                            <Input placeholder="Adresse mail" className={"input"} type="email" ref={emailRef} onInput={(e) => {
                                setEmail(e.target.value);
                            }} />
                            <Input placeholder="Nom" className={"input"} ref={nameRef} onInput={(e) => {
                                setName(e.target.value);
                            }}/>
                            <Input placeholder="Prénom" className={"input"} ref={prenomRef} onInput={(e) => {
                                setPrenom(e.target.value);
                            }} />
                            <Input placeholder="Date de naissance" className={"input"} size="md" type="date" ref={dateNaissanceRef} onInput={(e) => {
                                setDateNaissance(e.target.value);
                            }} />
                            <Input placeholder="Mot de passe" className={"input"} type="password" ref={passwordRef} onInput={(e) => {
                                setPassword(e.target.value);
                            }}/>
                            <Input placeholder="Confirmer le mot de passe" className={"input"} type="password" ref={confirmPasswordRef} onInput={(e) => {
                                setConfirmPassword(e.target.value);
                            }}/>
                            <RadioGroup defaultValue="H" ref={genreRef} onInput={(e) => {
                                setGenre(e.target.value);
                            }}>
                                <Center>
                                    <Stack direction="row" spacing={5}>
                                        <Radio value="H">Homme</Radio>
                                        <Radio value="F">Femme</Radio>
                                        <Radio value="O">Autre</Radio>
                                    </Stack>
                                </Center>
                            </RadioGroup>

                            <Center>
                                <Box flex={1} textAlign="center" flexDirection={"column"}>
                                    <button className={"button-glow m-10"} onClick={submitRegister}>
                                        S'inscrire
                                    </button>
                                    <p className={"error"} ref={errorRef}></p>
                                </Box>
                            </Center>
                        </FormControl>
                    </Box>
                    <p className={"text accountAlready"}>Déjà un compte ? <Link to={"/login"} className={"link"}>Connecte-toi</Link></p>
                </Box>
            </Center>
        </div>
    );
}
