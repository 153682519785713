import { Center, Heading, Box } from "@chakra-ui/react";
import "../styles/app.css";
import {useNavigate} from "react-router-dom";
import {useState, useEffect} from "react";
import axios from "axios";

export default function Home() {
    const navigate = useNavigate();

    useEffect(() => {
        axios.get("https://noz-games-api.krbk.dev/user/@me", {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            setUserData(res.data);
        }).catch((err) => {
            console.log(err);
            navigate("/login");
        });
    }, [])

    const [userData, setUserData] = useState({});

    return (
        <div className={"app"}>
            {
                userData.username ? (
                    <Center h="100vh">
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Heading as="h1" size="2xl" className={"text"}>
                                Bienvenue{userData.username ? `, ${userData.username}.` : "."}
                            </Heading>

                            <button className={"button-glow mt-20"} onClick={() => {
                                navigate("/profile");
                            }}>
                                Mon profil
                            </button>
                            <button className={"button-glow mt-10"} onClick={() => {
                                navigate("/game");
                            }}>
                                Mes demandes de jeu
                            </button>
                        </Box>
                    </Center>
                ) : (
                    <Center h="100vh">
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Heading as="h1" size="2xl" className={"text"}>
                                Chargement...
                            </Heading>
                        </Box>
                    </Center>
                )
            }
        </div>
    );
}
